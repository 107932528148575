import type { UseFetchOptions } from '#app'

export default function<T>(
  url: string | (() => string),
  options: UseFetchOptions<T> = {},
) {
  const stringUrl = typeof url === 'function' ? url() : url

  const { data } = useNuxtData(options.key ?? stringUrl)
  return useLazyFetch(url, {
    ...options,
    key: options.key ?? stringUrl,
    $fetch: useNuxtApp().$api,
    // if default value is not provided, use the value from useNuxtData
    // check if options.default is a function, if so, call it else use the value from useNuxtData as arrow function
    default: options.default ?? (() => data.value),
  })
}
